import { SignedIn, SignedOut, SignUpButton } from "@clerk/nextjs";
import Head from "next/head";
import Link from "next/link";
import { type GetStaticPropsContext } from "next/types";
import { Button } from "~/components/ui/button";

export default function About() {
  return (
    <>
      <Head>
        <title>About TrackTalk</title>
        <meta name="description" content="TrackTalk music platform" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <div className="mb-0 bg-[#0F0B17]">
        <div
          className="flex h-[500px] flex-col gap-12 bg-cover bg-no-repeat"
          style={{
            backgroundImage: "url(/About/cta.png)",
          }}
        >
          <h1 className="mx-auto max-w-4xl p-12 text-center text-3xl font-bold text-white md:text-5xl">
            TrackTalk is a platform for music lovers to connect, share, and grow
          </h1>
          <div className="flex justify-center">
            <SignedIn>
              <Link href="/explore">
                <Button className="w-32 bg-[#5F5CE3] text-white shadow-lg hover:bg-[#4F4CE3]">
                  Explore
                </Button>
              </Link>
            </SignedIn>
            <SignedOut>
              <Button className="w-32 bg-[#5F5CE3] text-white shadow-lg hover:bg-[#4F4CE3]">
                <SignUpButton mode="modal">Signup</SignUpButton>
              </Button>
            </SignedOut>
          </div>
        </div>
        <div className="grid grid-cols-2">
          <div>
            <img
              src="/About/img1.png"
              alt=""
              className="h-full w-full shadow-2xl"
            />
          </div>
          <div className="flex flex-col items-center gap-20 p-24 shadow-2xl">
            <h2 className="text-2xl font-bold">About TrackTalk</h2>
            <p className="max-w-2xl text-center text-xl">
              Welcome to TrackTalk, the premier platform for music enthusiasts
              and artists to share, discover, and discuss tracks. Our vibrant
              community consists of musicians, producers, audiophiles, and fans,
              all passionate about the art of music and its power to connect
              souls.
            </p>
          </div>
          <div className="flex flex-col items-center gap-20 p-24 shadow-2xl">
            <h2 className="text-2xl font-bold">
              Amplifying the Voice of Music Lovers
            </h2>
            <p className="max-w-2xl text-center text-xl shadow-2xl">
              Lorem ipsum, dolor sit amet consectetur adipisicing elit.
              Voluptate, sed cum numquam dolorum alias in sequi fuga et
              inventore ipsa rem perferendis quasi voluptatem esse, molestiae
              est nostrum! Et, ratione.
            </p>
          </div>
          <div className="shadow-2xl">
            <img src="/About/img2.png" alt="" className="h-full w-full" />
          </div>
        </div>
        <div className="p-14 text-center text-xl font-bold shadow-2xl">
          Why You should Tune in
        </div>
        <div className="grid grid-cols-2">
          <div
            style={{ backgroundImage: "url(/About/img3.png)" }}
            className="flex h-[700px] flex-col gap-24 bg-cover bg-no-repeat p-16 shadow-2xl"
          >
            <h3 className="text-2xl font-bold">Artists</h3>
            <ul className="flex list-inside list-disc flex-col gap-4 text-lg">
              <li>Share Your Music</li>
              <li>Get Discovered</li>
              <li>Connect with Fans</li>
            </ul>
          </div>
          <div
            style={{ backgroundImage: "url(/About/img4.png)" }}
            className="flex h-[700px] flex-col gap-24 bg-cover bg-no-repeat p-16 shadow-2xl"
          >
            <h3 className="text-2xl font-bold">Listeners</h3>
            <ul className="flex list-inside list-disc flex-col gap-4 text-lg">
              <li>Discover New Music</li>
              <li>Connect with Artists</li>
              <li>Discuss Tracks</li>
            </ul>
          </div>
          <div className="h-[600px] overflow-visible shadow-2xl">
            <img
              src="/About/img5.png"
              alt=""
              className="h-[900px] -translate-y-[300px]"
            />
          </div>
          <div
            style={{ backgroundImage: "url(/About/img6.png)" }}
            className="flex h-[600px] flex-col items-center gap-20 bg-cover bg-left bg-no-repeat p-24 shadow-2xl"
          >
            <h3 className="text-2xl font-bold">Let&apos;s Hit Play!</h3>
            <p className="max-w-xl text-center text-xl">
              The world of music is vast and ever-evolving, and with TrackTalk,
              you&apos;re right at its pulsating heart. Ready to dive into an
              ocean of melodies?
            </p>
            <SignedOut>
              <Button className="w-32 bg-[#5F5CE3] text-white shadow-lg hover:bg-[#4F4CE3]">
                <SignUpButton mode="modal">Signup</SignUpButton>
              </Button>
            </SignedOut>
            <SignedIn>
              <Link href="/explore">
                <Button className="w-32 bg-[#5F5CE3] text-white shadow-lg hover:bg-[#4F4CE3]">
                  Explore
                </Button>
              </Link>
            </SignedIn>
          </div>
        </div>
      </div>
    </>
  );
}

export async function getStaticProps({ locale }: GetStaticPropsContext) {
  const messages = (
    (await import(`@/../messages/${locale}.json`)) as {
      default: IntlMessages;
    }
  ).default;

  return {
    props: {
      messages,
    },
  };
}
